<template lang="pug">
include Topbar.pug
</template>

<script>
import _ from "underscore"
// import PubSub from "pubsub-js"
// import Api from "@/components/services/Api"
// import Login from "@/components/login/Login"
import { mapState } from 'vuex'
import Redirector from '@/services/redirector.js'


export default {
  name: 'Topbar',
  // components: {Login},
  components: {},
  mixins: [Redirector],
  computed: mapState([
    "token",
    "context",
    "type",
  ]),
  data () {
    return {
      megamenu: null,
      submegamenu: null,
      viewType: null,
      menuMobileVisible: false,
      categoryLaFederacion: null,
      categoryQueHacemos: null,
      categoryRegistro: null,
      categoryProfesionalesRegistrados: null,
      categoryRecursosCovid19: null,
    }
  },
  created() {
    const _this = this;

    this.refreshRoute(this.$route)

    this.$wagtail.getCategories([
      'la-federacion',
      'que-hacemos',
      'registro-de-profesionales',
      'por-que-contar-con-profesionales-registrados',
      'recursos-covid-19',
    ]).then(response => {
      let data = response.data
      _.each(data, item => {
        if (item.slug == "la-federacion") _this.categoryLaFederacion = item
        if (item.slug == "que-hacemos") _this.categoryQueHacemos = item
        if (item.slug == "registro-de-profesionales") _this.categoryRegistro = item
        if (item.slug == "por-que-contar-con-profesionales-registrados") _this.categoryProfesionalesRegistrados = item
        if (item.slug == "recursos-covid-19") _this.categoryRecursosCovid19 = item
      })
    }).catch(() => {})

    // PubSub.subscribe('login.show', () => {
    //   _this.showLogin();
    // });
  },
  watch: {
    '$route' (to) {
      this.refreshRoute(to)
      this.hideMenuMobile();
    }
  },
  methods: {
    refreshRoute: function(route) {
      this.viewType = (/Blog/.test(route.name)) || (/Post/.test(route.name)) ? "blog" : "default"
    },
    hideMenuMobile() {
      this.menuMobileVisible = false;
    },
    toggleMenuMobile() {
      this.menuMobileVisible = !this.menuMobileVisible;
    },
    showDevMessage() {
      this.$notify({
        group: "esreps",
        title: "En desarrollo",
        text: "Estamos terminando esta sección. En breve, estará disponible",
        type: "warn",
      });
    },
    // showLogin() {
    //   this.hideMenuMobile();
    //   this.$router.push({
    //     name: "LoginView"
    //   })
    // }
  }
}
</script>

<style lang="scss">
@import "./../../variables";
@import "./../../variables_colreps";
@import "./../../variables_mexreps";

@import "Topbar";
.context-colreps {
    @import "TopbarColreps";
}
.context-mexreps {
    @import "TopbarMexreps";
}
@import "logos";
@import "TopbarMenu";
</style>

