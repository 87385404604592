<template lang="pug">
  include ApplicationFooter.pug
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ApplicationFooter',
  computed: mapState([
    'context',
  ]),
  data() {
    return {
      viewType: null,
    }
  },
  created() {
    // const _this = this
    this.refreshRoute(this.$route)
  },
  watch: {
    $route(to) {
      this.refreshRoute(to)
    },
  },
  methods: {
    refreshRoute: function(route) {
      this.viewType =
        /Blog/.test(route.name) || /Post/.test(route.name) ? 'blog' : 'default'
    },
  },
}
</script>

<style scoped lang="scss">
@import './../variables';
@import './../variables_colreps';
@import './../variables_mexreps';
@import "./ApplicationFooter";
</style>
