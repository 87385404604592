export const settings = {
  apiBaseUrl: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:8001',
  iamApiUrl: process.env.VUE_APP_IAM_API_URL ? process.env.VUE_APP_IAM_API_URL : 'http://localhost:8000',
  iamLoginUrl: process.env.VUE_APP_IAM_LOGIN_URL ? process.env.VUE_APP_IAM_LOGIN_URL : 'http://localhost:8080',
  cookiesDomain: process.env.VUE_APP_COOKIES_DOMAIN ? process.env.VUE_APP_COOKIES_DOMAIN : 'localhost',
}

export function Settings(Vue, options) {
  Vue.prototype.$settings = settings;
}
